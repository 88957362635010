import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./sass/config.sass"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ front, children, props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(filter: {frontmatter: {tag: {eq: "Solution"}}}, sort: {fields: frontmatter___order, order: ASC}) {
        nodes {
          id
          frontmatter {
            colour
            title
            path
            linkTitle
          }
        }
      }
    }
  `)

  return (
    <>
      <Header solutions={data.allMarkdownRemark.nodes} siteTitle={data.site.siteMetadata.title} colour={front}/>
      <main>{children}</main>
      <Footer siteTitle={data.site.siteMetadata.title} colour={front} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
