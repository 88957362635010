import React from "react"
import Form from "./form"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMedium,
  faLinkedin,
  faTwitterSquare,
  faGithubSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { Link } from "gatsby"
import { getColorClass } from '../utils/getColorClass'
config.autoAddCss = false

const Footer = ({ siteTitle, colour }) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: {order: ASC, fields: frontmatter___order}) {
        edges {
          node {
            frontmatter {
              path
              title
              linkTitle
            }
            id
          }
        }
      }
    }
  `)
  // We use this to get the current color as a name
  const colorClass = getColorClass(colour)
  return (
    <footer id="footer">
      {/* <div
        style={{ background: `${colour ? colour : "#019ADE"}` }}
        className="contact"
      >
        <Form colour={colour}/>
      </div> */}
      <div className="container -footer">
        <div className="sect1">
          <Link to="/" className="heading">
            {siteTitle.toUpperCase()}
          </Link>
          <address>
           120 East 8th St, STE 810
            <br />
            Los Angeles CA, 90014
            <br />
            White Cane, Inc. (DBA Teleidoscope)
          </address>
          <Form colour={colour} />
        </div>
        <div className="sect2">
          <div className="row">
            <div className="item">
              <h6
                style={{ color: `${colour ? colour : "#019ADE"}` }}
                className="subheading"
              >
                Company
              </h6>
              <a href="/" className={colorClass}>Jobs</a>
            </div>
            <div className="item">
              <h6
                style={{ color: `${colour ? colour : "#019ADE"}` }}
                className="subheading"
              >
                Solutions
              </h6>
              {data.allMarkdownRemark.edges.map((post, index) => (
                <div key={"footer_link_list" + post.node.id + index}>
                  <Link to={post.node.frontmatter.path} className={colorClass}>
                    {post.node.frontmatter.linkTitle}
                  </Link>
                </div>
              ))}
            </div>

          </div>
          <div className="row">
          <div className="item">
              <h6
                style={{ color: `${colour ? colour : "#019ADE"}` }}
                className="subheading"
              >
                Legal
              </h6>
              <a href="/" className={colorClass}>Privacy</a>
              <a href="/" className={colorClass}>Terms</a>
            </div>
            <div className="item">
              <h6
                style={{ color: `${colour ? colour : "#019ADE"}` }}
                className="subheading"
              >
                Resources
              </h6>
              <a href="/" className={colorClass}>Demos</a>
              <a
                href="/blog"
                className={colorClass}
                aria-label="Blog"
              >
                Blog
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="icons">
        <a
          href="https://github.com/teleido"
          className={colorClass}
          aria-label="github"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <FontAwesomeIcon icon={faGithubSquare} />
        </a>
        <a
          href="https://www.linkedin.com/company/teleidoscope"
          className={colorClass}
          aria-label="linked"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCh5wajMC-ICKfgpXvoxawvA/videos"
          className={colorClass}
          aria-label="youtube"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <FontAwesomeIcon icon={faYoutubeSquare} />
        </a>
        <a
          href="https://twitter.com/teleidoscope1"
          className={colorClass}
          aria-label="twitter"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <FontAwesomeIcon icon={faTwitterSquare} />
        </a>
        <a
          href="https://medium.com/teleidoscope"
          className={colorClass}
          aria-label="medium"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <FontAwesomeIcon icon={faMedium} />
        </a>
      </div>
      <div
        className="sect4"
        style={{ borderTop: `6px solid ${colour ? colour : "#019ADE"}` }}
      >
        <div className="container -end">
          <div className="end">Teleidoscope is made with ♡ in LA</div>
          <div className="copy">Copyright © Teleidoscope 2020</div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
