import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { getColorClass } from '../utils/getColorClass'


const Header = ({ siteTitle, colour, solutions }) => {

  const [state, setState] = useState({visible: false})
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  };
  const toggleLeave = (e) => {
    e.currentTarget.blur()
    let x = window.matchMedia("(min-width: 768px)")
    if (x.matches){
      setOpen(false)
    }
  };
  // toggle dropdown visibility
  const toggleVisibility = () => {
    setState({
      ...state,
      visible: !state.visible,
    });
  };
  // adds support to open menu with keyboard navigation
  const buttonPress = event => {
    const key = event.keyCode
    if (key === 13 ) {
      event.preventDefault()
      return toggleVisibility()
    }
  }
  const getMaxHeight = (allHeight, subList) => {
    if (!subList) {
      let height = 53.67 + 50 * 3
      let extras = 0
      allHeight.forEach(() => (extras = extras + 50))
      let total = height + extras + "px !important"
      return total
    } else if (subList) {
      let extras = 53.67
      allHeight.forEach(() => (extras = extras + 50))
      let total = `${extras}px !important`
      return total
    }
  }

  // We use this to get the current color as a name
  const colorClass = getColorClass(colour)
  return (
    <header style={{borderBottom: `6px solid ${colour ? colour : "#019ADE"}`}} data-active={state.visible}>
      <div data-open={open} data-active={state.visible} className="container -header" css={{ '[data-active="true"]': { maxHeight: getMaxHeight(solutions)}}}>
        <div className="mob-nav">
          <Link className={`site-title ${colorClass}`} to="/">
            {siteTitle.toUpperCase()}
          </Link>

          <div
            onClick={toggleVisibility}
            data-active={state.visible}
            tabIndex="0"
            id="menu-btn"
            className="menu-btn"
            onKeyDown={buttonPress}
            role="button"
          >
            <div className="btn-line" style={{background: `${colour ? colour : "#019ADE"}`}}></div>
            <div className="btn-line" style={{background: `${colour ? colour : "#019ADE"}`}}></div>
            <div className="btn-line" style={{background: `${colour ? colour : "#019ADE"}`}}></div>
          </div>
        </div>
        <div
          data-active={state.visible}
          aria-hidden={!state.visible}
          className="site-nav"
        >
          <div
            data-open={open}
            className={`nav-links ${colorClass} list`}
            onClick={toggleOpen}
            onKeyDown={buttonPress}
            onMouseLeave={toggleLeave}
            tabIndex="0"
            role="button"
            data-active={state.visible}
            aria-hidden={!state.visible}
            style={{  userSelect: "none" }}
          >
            Solutions
            <div data-open={open} className="sub-list" css={{ '[data-open="true"]': { maxHeight: getMaxHeight(solutions,true)}}}>
              {solutions.map((product, index) => (
                <Link
                key={product.frontmatter.path+index}
                css={{ '[data-open="true"]': { maxHeight: getMaxHeight(solutions,true)}}}
                style={{borderBottomColor: `${colour ? colour : "#019ADE"}`, userSelect: "none"}}
                to={product.frontmatter.path}
                className={`nav-links ${colorClass}`}
                >
                  {product.frontmatter.linkTitle}
                </Link>
              ))}
            </div>
          </div>
          {/*<a
          href="https://medium.com/teleidoscope"
          aria-label="Medium Blog"
          target="_blank"
          rel="noopener noreferrer nofollow"
          data-active={state.visible}
          aria-hidden={!state.visible}
          className={`nav-links ${colorClass}`}
          >
            Blog
          </a>*/}
          <Link
            data-active={state.visible}
            aria-hidden={!state.visible}
            className={`nav-links ${colorClass}`}
            to="/blog"
            onClick={toggleVisibility}
          >
            Blog
          </Link>
          <Link
            data-active={state.visible}
            aria-hidden={!state.visible}
            className={`nav-links ${colorClass}`}
            to="/#footer"
            onClick={toggleVisibility}
          >
            Contact
          </Link>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
