import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { getColorClass } from '../utils/getColorClass'

config.autoAddCss = false

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Form({colour}) {
  const [state, setState] = React.useState({email: ""})
  const [loading, setLoad] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [disable, setDisable] = React.useState(true)

  const handleChange = (e) => {
    setState({ [e.target.name]: e.target.value })
    state.email === "" ? setDisable(true) : setDisable(false)
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    setLoad(true)
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })  
      .then(() =>setLoad(false))
      .then(() =>setSuccess(true))
      .then(() =>setState({email: ""}))
      .then(setTimeout(() => {
        setSuccess(false)
      }, 3000))
      .catch((error) => alert(error))
  }
  // We use this to get the current color as a name
  const colorClass = getColorClass(colour)
  return (
    <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <input disabled={loading} data-success={success} type="email" name="email" id="email" aria-label="Email Input" placeholder="Enter Work Email" onChange={handleChange} value={state.email}/>
        <button data-success={success} disabled={!state.email} style={{ background: `${colour ? colour : "#019ADE"}` }} className={`submit-button ${colorClass}`}>
          { !loading && !success && "Request a demo"}
          { loading && <FontAwesomeIcon className="spin" icon={faCircleNotch} />}
          { !loading && success && <FontAwesomeIcon icon={faCheckCircle} />}
        </button>
    </form>
  )
}
